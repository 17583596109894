#DiagnosisList {
  width: 100%;
  height: 100%;
}

input {
  width: 100%;
}

.diagnosis-header {
  color: black;
}

.form-check .form-check-input {
  margin-left: 0;
  border: 1px solid rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.exacerbation-check {
  display: flex;
  justify-content: center;
  align-items: center;
}

.expandable-list {
  position: absolute;
  z-index: 12;
  background-color: #f8f9fa;
}

.expand-btn-holder {
  position: sticky;
  top: 100%;
  z-index: 15;

  width: 100%;
  height: 0;
}

.expand-btn {
  position: absolute;
  top: -2.75rem;
  right: 0.25rem;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 2rem;
  height: 2rem;
  padding: 0;
}

/* dropdown */
.dropdown-menu {
 z-index: 1;
 max-height: 5.6rem;
 overflow-y: auto;
}

.dropdown-item:active {
  background-color: initial;
  color: initial;
}

.dropdown_act.btn {
  padding: 6px 0px;
}

.dropdown_act.btn:hover {
  color: #0d6efd;
}

.dropdown_act.btn:focus {
  box-shadow: none;
}
