#ServicesTable {
  height: 100%;
  width: 100%;
}

.serviceCell {
  padding: 1%;
}

.serviceCell:focus-within,
.serviceCell:hover {
  background: #3891672b;
}

.serviceCell:nth-child(n + 4) .custom-select__menu {
  bottom: 40px;
  top: auto;
  z-index: 100;
}

.custom-select__menu .custom-select__menu-list {
  max-height: 250px;
}
