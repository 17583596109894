body {
  background-color: #d9ecff;
}

.App {
  text-align: center;
}

#root {
  height: 100%;
}

#header-block {
  width: 100%;
  max-width: 1920px;
  padding-top: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.25rem;

  display: flex;
  align-items: center;

  position: relative;
}

.logo__wrapper {
  width: 250px;
}

.lang-switcher {
  height: 40px;
  margin-left: 1rem;
}

.lang-select {
  padding-right: 0.5rem;
  background-image: none;
  cursor: pointer;
}

.manual-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 170px;

  width: 46px;
  height: 38px;
  padding: 0;
}

.manual-btn svg {
  fill: currentColor;
}

.logout-btn {
  margin-left: auto;
}

@media screen and (min-width: 1440px) {
  .logo__wrapper {
    width: 300px;
  }

  .lang-switcher {
    height: 56px;
    margin-left: 2rem;
  }
}
