.react-virtualized-menu-placeholder {
  margin-top: 8px;
  color: #9a9a9a;
  text-align: center;
}

.react-virtualized-list-wrapper {
  background-color: white;
  border-radius: 4px;
}

.react-virtualized-list-wrapper li {
  list-style: none;
}