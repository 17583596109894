body,
html {
  width: 100%;
  height: 100vh;
}

#MainPage {
  text-align: center;
  width: 100%;
  height: 100vh;
  padding: 50px 0 0;
}

.green-bg {
  background-color: #b0ffda;
}

.action-button svg {
  fill: currentColor;
}

/* Textarea block styles */

.text-block {
  position: relative;
}

textarea {
  width: 100%;
  height: 100%;
  resize: none;
  line-height: 1.4;
  display: block;
}

.input-group {
  flex-wrap: nowrap;
  flex-direction: column;
  height: 100%;
}

.input-group-text {
  border-radius: 0;
  padding: 0.5rem 0.75rem;

  color: #212529c7;
  font-size: 14px;
  line-height: 1.2;
  text-align: left;
  white-space: initial;
}

.form-control.text-box {
  width: 100%;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0px;
  margin-top: -1px;
}

/* / */

#main-block {
  width: 100%;
  height: 90%;
}

.tables-block {
  height: 100%;
  position: relative;
}

.button-block {
  width: 100%;
  height: 10%;
}

.default-block {
  width: 100%;
  height: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.textarea-container {
  width: 100%;
  height: 32%;
  padding-top: 5px;
  padding-bottom: 2px;
}

.textarea-container > .col-md-4 {
  padding-right: 1rem;
}

.form-control {
  border-radius: 0;
  line-height: 1.4;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.row > * {
  padding-right: 0;
  padding-left: 0;
}

.btn-row {
  padding-bottom: 1rem;
}

.table-container {
  width: 100%;
  height: 58%;
  padding-top: 5px;
  padding-bottom: 2px;
}

.column {
  height: 100%;
  padding-left: 0;
}

.list {
  overflow-y: scroll;
  height: 100%;
}

.expandable-wrapper {
  position: relative;
}

table {
  width: 100%;
  font-size: 14px;
}

.table > :not(:first-child) {
  border-top: none;
}

.table > tbody > tr {
  height: 50px;
  width: 100%;
}

.table > thead {
  height: 50px;
  vertical-align: middle;
  line-height: 1.2;
  color: #232526;

  background: aliceblue;
  position: sticky;
  top: 0;
  z-index: 5;
}

.table > thead::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 1px;
  background: #41464b;
  width: 100%;
  height: 1px;
}

.result-btn-decline {
  margin-left: 1rem;
}

.action-icon {
  display: flex;
  padding: 0;
  border: none;
  background: transparent;
  justify-content: center;
  align-items: center;
}

.action-icon svg {
  fill: #232526;
  transition: fill 250ms ease-in-out;
}

.action-icon.action-icon__trash:hover svg {
  fill: #c01616;
}

.action-icon.action-icon__copy:hover svg,
.action-icon.action-icon__eye:hover svg {
  fill: #0d6efd;
}

.sticky-cell {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 1;
}

/*Tooltip styles*/
.tooltip-inner {
  max-width: 350px;
}

.tooltip-approve .tooltip-inner {
  max-width: 140px;
}

/* Preoperative button */

.preoperative-btn {
  width: 46px;
  height: 38px;
  padding: 0;

  position: relative;
}

.preoperative-btn::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 1;
}

.preoperative-btn:hover {
  color: #198754;
  background-color: transparent;
  border-color: #198754;
}

.preoperative-btn.active:hover {
  color: #ffffff;
  background-color: #198754;
  border-color: #198754;
}

/* Tables border */

.table__bordered {
  border: 1px solid #ced4da;
}

/* Media Queries */

@media screen and (min-width: 1440px) {
  #MainPage {
    padding: 64px 0 0;
  }

  #main-block {
    height: 92%;
  }

  table,
  .input-group-text {
    font-size: 1rem;
  }

  .table-container {
    height: 61%;
  }

  .button-block {
    height: 7%;
  }
}
