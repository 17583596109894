/*Custome styles for redone select*/
.custom-option {
    transition: background-color 60ms;
  }
  .custom-option.custom-select__option:hover {
    transition-delay: 60ms;
    background: #deebff;
  }
  .custom-option.custom-select__option--is-focused {
    background: none;
  }
  .custom-option.custom-select__option--is-selected {
    background: #2684FF;
  }
  