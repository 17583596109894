.overlay-loader__wrapper {
  position: fixed;
  inset: 0;
  z-index: 20;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 5rem;
}
